import React, { createElement } from "react"
import { RichText } from "prismic-reactjs"

import {
  Button,
  convertCentsToEuro,
  formatIntervalUnit,
  CURRENCIES,
  isZeroDecimalCurrency,
  propsWithUniqueKey,
  htmlSerializerUpdateStyle,
} from "@lesmills/gatsby-theme-common"

const Subscription = ({
  offer,
  prismicData,
  handleRedeemNow,
  layoutData,
  retailProducts,
  underRedeem,
}: {
  offer: object,
  prismicData: object,
  underRedeem: objecy,
  handleRedeemNow: () => void,
  layoutData: object,
  retailProducts: Array,
}) => {
  const getTrialUnit = (trial_interval_unit, trial_interval) => {
    const unit =
      trial_interval_unit +
      `${trial_interval && trial_interval > 1 ? "_plural" : "_singular"}`

    return layoutData[unit].text
  }
  const { product_price_point = {}, currency, recurring } = offer
  const { trial_interval, trial_interval_unit } = product_price_point || {}

  const retailProduct =
    retailProducts.find(item => {
      const { interval, interval_unit } = item.product_price_point || {}

      return (
        product_price_point.interval === interval &&
        product_price_point.interval_unit === interval_unit
      )
    }) || {}

  const normalPrice = convertCentsToEuro(
    product_price_point.price_in_cents,
    currency,
    2
  )
  const offerPrice = convertCentsToEuro(
    product_price_point.final_price_in_cents,
    currency,
    2
  )
  const retailPrice = convertCentsToEuro(
    retailProduct.product_price_point &&
      retailProduct.product_price_point.price_in_cents,
    currency,
    2
  )
  const unit = formatIntervalUnit(product_price_point, layoutData)
  const currencySymbol = CURRENCIES[currency]
  const {
    usually = {},
    redeem_button = {},
    try_it_on_us = {},
    one_off_discount_offer_interval_unit = {},
    offer_interval_unit = {},
    one_off_discount_note = {},
  } = prismicData

  // https://lesmillsinternational.atlassian.net/browse/AB2B-758
  let paymentNote = recurring === false ? one_off_discount_note : usually
  const offerIntervalUnit =
    recurring === false
      ? one_off_discount_offer_interval_unit.text
      : offer_interval_unit.text
  paymentNote = paymentNote.raw.map(item => ({
    ...item,
    text: item.text
      .replace(/@normal_price/g, `${currencySymbol}${normalPrice}`)
      .replace(/@interval_unit/g, unit),
  }))

  const trialNotes = try_it_on_us.raw || []

  const trialNoteWithTrial = () =>
    trialNotes.map(item => ({
      ...item,
      text: item.text.replace(
        "@trial_interval",
        `${trial_interval} ${getTrialUnit(trial_interval_unit, trial_interval)}`
      ),
    }))

  const trialNoteWithoutTrial = () =>
    trialNotes.map(item => {
      let text = item.text ? item.text.split(".") : []

      text = text.filter(t => !t.includes("@trial_interval")).join(".")

      return {
        ...item,
        text,
      }
    })

  const htmlSerializerUpdate = style => {
    const htmlSerializer = (type, element, children, key) => {
      let props = {}

      const text = element.text
      props = Object.assign({
        className: style,
        dangerouslySetInnerHTML: {
          __html: text
            .replace("\n", "<br>")
            .replace(
              /@retail_price/g,
              `<span class="line-through">${currencySymbol}${retailPrice}</span>`
            ),
        },
      })

      return createElement("p", propsWithUniqueKey(props, key), children)
    }

    return htmlSerializer
  }

  return (
    <div className="mb-20 mx-10 pt-30 pb-35 px-20 text-center bg-white flex flex-col items-center shadow-2xl">
      <p className="font-primary relative m-0">
        <span className="text-67l md:text-xxl font-primary">
          {currencySymbol}
        </span>
        <span className="sm:text-95xl text-83xl font-primary text-gray-800">
          {parseInt(offerPrice)}
        </span>
        {!isZeroDecimalCurrency(currency) && (
          <span className="absolute top-18 text-67l md:text-xxl font-primary text-gray-500">
            .{offerPrice.split(".")[1]}
          </span>
        )}
        <span
          className={`inline-block text-left text-base text-gray-800 font-base-light text-gray-800 ${
            recurring === false ? "max-w-124" : "max-w-104"
          }`}
        >
          {offerIntervalUnit.replace(/@interval_unit/g, unit)}
        </span>
      </p>

      {/* https://lesmillsinternational.atlassian.net/browse/AB2B-758 */}
      {/* No show Usually if no discount */}
      <div className="min-h-64">
        {recurring !== null && (
          <RichText
            render={paymentNote}
            htmlSerializer={htmlSerializerUpdate(
              "text-3xs md:text-base font-base-light text-center text-error leading-18 sm:leading-22 mb-21"
            )}
          />
        )}
      </div>

      <RichText
        render={
          trial_interval > 0 ? trialNoteWithTrial() : trialNoteWithoutTrial()
        }
        htmlSerializer={htmlSerializerUpdateStyle(
          "",
          "font-inter-regular text-gray-800 leading-22 text-3xs"
        )}
      />
      <Button
        handleOnClick={handleRedeemNow}
        className="mt-20 uppercase w-227 h-logo-lmod-base focus:outline-none text-black font-primary bg-orange-400 text-center"
        innerRef={underRedeem}
      >
        {redeem_button.text}
      </Button>
    </div>
  )
}

export default Subscription
